@import '../../variables.scss';

.menu {
    background-color: black;
    content: 'logo';
    padding: 0 30px;
    position: fixed;
    width: 100%;
    height: 64px;
    z-index: 999;
    @include mobile-tablet {
        padding: 0;
    }
    &__logo {
        display: none;
        @include mobile-tablet {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            color: white;
            z-index: 9;
            font-size: 38px;
            padding: 5px 20px;
            font-family: $logo-font;
            &:hover,
            &:focus {
                color: white;
            }
        }
    }

    &__links {
        background-color: inherit;
        &__logo {
            a {
                font-family: $logo-font;
                font-size: 40px;
                margin-right: 100px;
                &:hover {
                    color: white;
                }
            }
        }
        @include mobile-tablet {
            position: relative;
            &__logo {
                display: none !important;
            }
        }
        &__item a {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 2px;
            text-transform: lowercase;
            &:hover,
            &:focus {
                color: $main-blue;
            }
        }
        .sidenav-trigger {
            &:hover,
            &:focus {
                color: $main-blue;
            }
        }
    }
    #mobile-nav {
        background-color: #000000e3;
        .menu__links__item a {
            color: white;
            &:focus {
                color: $main-blue;
            }
        }
    }
}