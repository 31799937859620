@import '../../variables.scss';

.articles {
    margin-top: 64px;
    &__header {
        @include header;
        background: url('../../img/planet.jpg') no-repeat center;
        background-size: cover;
        padding-top: 120px;
        font-size: 40px;
        @include mobile-tablet {
            font-size: 30px;
            padding-top: 60px;
        }
    }
    &__cards {
        width: 70%;
        margin: 0 auto 80px;
        &__item {
            @include border;
            &__img.card-image {
                img {
                    width: auto;
                    object-fit: cover;
                    @include mobile-tablet {
                        width: 100%;
                        height: auto;
                    }
                    @media screen and (min-width: 601px) {
                        width: auto;
                        height: 200px;
                    }
                    @media screen and (min-width: 1343px) {
                        height: 290px;
                    }
                }
            }
            &__title {
                font-size: 20px;
                font-weight: bold;
                display: block;
                margin-bottom: 10px;
            }
            &__text {
                overflow: auto !important;
            }
            &__link {
                &__a {
                    color: $main-blue !important;
                    font-weight: 700;
                    &:hover {
                        color: darken($main-blue, 20%) !important;
                    }
                    &:focus {
                        color: darken($main-blue, 30%) !important;
                    }
                }
            }
        }
        @include mobile-tablet {
            width: 90%;
        }
    }
}