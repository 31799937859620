@import url('https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'materialize-css/sass/materialize.scss';
@import url('https://fonts.googleapis.com/css?family=Squada+One');
@import url('https://fonts.googleapis.com/css?family=Black+Ops+One');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.menu {
  background: black;
}