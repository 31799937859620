@import '../../variables.scss';

.contact {
    margin-top: 64px;
    &__header {
        @include header;
        background: url('../../img/machine.jpg') no-repeat center top;
        background-size: cover;
        padding-top: 70px;
        span {
            font-size: 70px;
            margin-top: 10px;
            display: block;
        }
        @include mobile-tablet {
            font-size: 22px;
            padding-top: 40px;
            span {
                font-size: 52px;
                margin-top: 0;
            }
        }
    }
    &__container {
        width: 50%;
        margin: 0 auto 80px;
        @include mobile-tablet {
            width: 90%;
        }
        &__form {
            @include border;
            form {
                padding: 0 130px;
                @include mobile {
                    padding: 0 40px;
                }
                @include tablet {
                    padding: 0 100px;
                }

                input[type=text]:not(.browser-default):focus:not([readonly]),
                textarea.materialize-textarea:focus:not([readonly]) {
                    &:focus {
                        border-bottom: 1px solid $main-blue;
                        box-shadow: 0 1px 0 0 $main-blue;
                    }
                }
                input[type=text]:not(.browser-default):focus:not([readonly]) + label {
                    color: $main-blue;
                }
                label {
                    color: $gray;
                }
            }
            &__btn {
                background-color: $main-blue;
                &:hover {
                    background-color: darken($main-blue, 20%);
                }
                &:focus {
                    background-color: darken($main-blue, 30%);
                }
            }
            .gotcha {
                display: none;
            }
        }
    }
}