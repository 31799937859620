@import '../../variables.scss';

.logos {
    text-align: center;
    &__item {
        display: inline-block;
        padding: 20px;
        opacity: 0.5;
        @include mobile {
            padding: 0 20px;
        }
        a {
            img {
                width: 100px;
                height: auto;
            }
        }
        &:hover {
            opacity: 1;
        }
    }
}