@import '../../variables.scss';

.industries {
    width: 60%;
    margin: 0 auto;
    &__info {
        background-color: $gray;
        height: auto;
        margin: 0 auto 50px;
        color: white;
        padding: 40px;
        font-size: 18px;
        line-height: 26px;
        text-indent: 20px;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }
    &__cards {
        margin: 0 auto 80px;
        @include card;
        &__item {
            @include border;
            &__img img {
                height: 200px;
            }
            &__title span {
                color: $gray;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
    @include mobile-tablet {
        width: 90%;
        &__info {
            padding: 20px;
            font-size: 16px;
        }
    }
}