.not-found{
    height: 100vh;
    text-align: center;
    padding: 100px 50px;
    h2 {
        font-size: 120px;
        font-weight: bold;
    }
    span {
        font-size: 80px;
    }
    p {
        font-size: 34px;
    }
}