// variables
$main-blue: #71c1ec;
$gray: #333;

$tablet-width: 768px;
$desktop-width: 992px;
$mobile-width: 425px;

$logo-font: 'Squada One', 'sans-serif';
// $logo-font: 'Black Ops One', cursive;

// mixins
@mixin header {
  width: 100%;
  height: 300px;
  margin-bottom: 80px;
  background-color: black;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  @include mobile-tablet {
    height: 170px;
  }
}

@mixin card {
  display: grid;
  grid-gap: 63px 20px;
  grid-template-columns: repeat(auto-fill,minmax(280px,1fr));
}

@mixin border {
  border: solid 1px #eee;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 14px 0 rgba(0,0,0,.04);
  box-shadow: 0 1px 14px 0 rgba(0,0,0,.04);
}

// media queries
@mixin mobile-tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width + 1}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin desktopXS {
  @media (min-width: #{$tablet-width + 1}) {
    @content;
  }
}