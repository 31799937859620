@import '../../variables.scss';

.modal-success {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    background: black;
    color: white;
    width: 40%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 100px;
    font-size: 28px;
    font-weight: 700;
    span {
        margin-top: 20px;
        font-size: 30px;
        display: block;
    }
  }

  .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      color: white;
      &:hover {
          color: $main-blue;
      }
  }